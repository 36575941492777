import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules'
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import { Our_Partner } from '../../helper/imageConstant';

const OurpartnerSection = () => {
    return (
        <section className='my-5 lg:my-5'>
            <div className='max-w-7xl h-full mx-auto overflow-hidden'>
                <div className='max-w-[1400px] mx-auto'>
                    <h2 className='mb-2 lg:mb-0 text-center lg:text-start text-2xl lg:text-[32px] text-[#212121] font-semibold'>Our partners</h2>
                    <p className='lg:w-[55%] ml-auto  text-base text-center pb-3 mb-5 lg:mb-0'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
                </div>
                <div className='relative'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true
                            },
                            // 768: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 40,
                            // },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 50,
                            // },
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom',
                        }}
                        loop={true}
                        modules={[Navigation]}
                        className="mySwiper !pb-2 lg:!pb-10"
                    >
                        <SwiperSlide>
                            <div className='w-full grid grid-cols-2 gap-5 lg:gap-20'>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-full grid grid-cols-2 gap-5 lg:gap-20'>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-full grid grid-cols-2 gap-5 lg:gap-20'>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-full grid grid-cols-2 gap-5 lg:gap-20'>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                                <div className=''>
                                    <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto grayscale object-contain' />
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* Custom Navigation Buttons */}
                        <div className='flex justify-center items-center gap-2 mt-10'>

                            <div className="swiper-button-prev-custom bg-transparent text-xl text-[#000000] border rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowLeft /></div>
                            <div className="swiper-button-next-custom bg-[#FB8500] shadow-md shadow-[#FB8500] text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowRight /></div>
                        </div>

                    </Swiper>
                </div>
            </div>
        </section>)
}

export default OurpartnerSection