import React, { useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';



const FAQItem = ({ faq, isOpen, onClick }) => (
    <div className="bg-collapse-bg bg-no-repeat border rounded-xl my-2">
        <div
            className="flex items-center p-2 lg:p-4 gap-3 cursor-pointer"
            onClick={() => onClick(faq.id)}
        >
            <span className='bg-gradiant-yellow2 text-sm lg:w-9 lg:h-9 text-center font-semibold p-2 rounded-full'>{faq?.sr}</span>

            <div className='w-full flex justify-between items-center'>

                <span className='text-[15px] lg:text-base'>{faq.question}</span>
                <span>
                    {isOpen ?
                        <div className='bg-gradiant-yellow3 text-[#000000] p-1 text-xl rounded-full'>
                            <FaCaretUp />
                        </div>
                        :
                        <div className='bg-gradiant-yellow3 text-[#000000] p-1 text-xl rounded-full'>
                            <FaCaretDown />
                        </div>

                    }
                </span>
            </div>
        </div>
        {isOpen && <div className="p-2 lg:p-4 text-[15px] lg:text-base transition-all ease-in-out duration-200">{faq.answer}</div>}
    </div>
);
const FaqSection = ({ data }) => {
    const [openId, setOpenId] = useState(null);

    const handleClick = (id) => {
        setOpenId(openId === id ? null : id);
    };
    return (
        <>
            <section className="max-w-7xl mx-auto my-10">
                <h2 className="text-xl lg:text-3xl font-bold mb-5 lg:mb-10">Frequently asked questions !!</h2>
                {data?.map((faq) => (
                    <FAQItem
                        key={faq.id}
                        faq={faq}
                        isOpen={openId === faq.id}
                        onClick={handleClick}
                    />
                ))}
            </section>
        </>
    )
}

export default FaqSection