import axios from 'axios';
import {API_URL} from '../config';
import {FETCH_USERS} from '../api';
console.log('FETCH_USERS', API_URL,FETCH_USERS)

// Action Types
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';



// Action Creators
const fetchProductsRequest = () => ({ type: FETCH_PRODUCTS_REQUEST });
const fetchProductsSuccess = (products) => ({ type: FETCH_PRODUCTS_SUCCESS, payload: products });
const fetchProductsFailure = (error) => ({ type: FETCH_PRODUCTS_FAILURE, payload: error });



// Thunks
export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch(fetchProductsRequest());
    try {
      const response = await axios.get(`${API_URL}${FETCH_USERS}`);
      dispatch(fetchProductsSuccess(response.data));
    } catch (error) {
      dispatch(fetchProductsFailure(error.message));
    }
  };
};

// export const addProduct = (product) => {
//   return async (dispatch) => {
//     dispatch(addProductRequest());
//     try {
//       const response = await axios.post('/api/products', product);
//       dispatch(addProductSuccess(response.data));
//     } catch (error) {
//       dispatch(addProductFailure(error.message));
//     }
//   };
// };
