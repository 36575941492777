import React, { useState } from 'react'
import HeroSection from '../home/HeroSection'
import { Bookingleftside, Cloudsandcity, Our_Partner, TAB1, TAB2, TAB3, TAB4 } from '../../helper/imageConstant'
import FaqSection from '../home/FaqSection'
import { bookingData } from '../../helper/constant'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go'
import Bookingcomponent from '../../component/Bookingcomponent'

const DatePicker = ({ date, onDateChange }) => {
    const dates = ["13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024",];
    const initialIndex = dates.indexOf(date);
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    return <>
        <div className='w-2/4 mx-auto relative'>
            <Swiper
                spaceBetween={10}
                slidesPerView={3}
                loop={true}
                centeredSlides={true}
                onSlideChange={(swiper) => {
                    setActiveIndex(swiper.realIndex);
                    onDateChange(dates[swiper.activeIndex])
                }}
                initialSlide={initialIndex}
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next-custom',
                    prevEl: '.swiper-button-prev-custom',
                }}
                className="mySwiper date_slider  "
            >
                {dates.map((date, index) => (
                    <SwiperSlide key={index} className=''>
                        <ul className=' '>
                            <li className={`text-center text-sm px-7 py-3 border rounded-full cursor-pointer ${activeIndex === index ? 'bg-gradiant-yellow4' : 'bg-transparent'}`}>

                                {date}
                            </li>
                        </ul>
                    </SwiperSlide>
                ))}
                {/* Custom Navigation Buttons */}
                {/* <div className=' mt-10'> */}
                <div className="swiper-button-prev-custom absolute top-0 left-0 z-50 bg-gradiant-yellow4 text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75"><GoArrowLeft /></div>
                <div className="swiper-button-next-custom absolute top-0 right-0 z-50 bg-gradiant-yellow4 text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75"><GoArrowRight /></div>
                {/* </div> */}
            </Swiper>
        </div >
    </>
};
const TripDetails = () => {
    const trips = [
        { time: '10:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '11:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '12:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '01:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '02:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '03:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '10:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '11:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '12:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '01:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '02:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '03:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
    ];
    return <>
        <Swiper
            spaceBetween={20}
            slidesPerView={2}
            // centeredSlides={true}
            loop={true}
            breakpoints={{
                640: {
                    slidesPerView: 7,
                    spaceBetween: 20,
                    loop: true,
                    centeredSlides: true

                },
                // 768: {
                //     slidesPerView: 4,
                //     spaceBetween: 40,
                // },
                // 1024: {
                //     slidesPerView: 4,
                //     spaceBetween: 50,
                // },
            }}
            className="mySwiper"

        >
            {trips.map((trip, index) => (
                <SwiperSlide key={index}>
                    <div className="p-4 py-4 border border-[#212121] rounded-[20px] space-y-3">
                        <div>
                            <p className='text-2xl lg:text-[32px] font-semibold'>{trip.time} <spn className={"text-[20px] font-normal"}>{trip?.ampm}</spn></p>
                            <p className='text-xs lg:text-[13px] text-end'>
                                {trip?.tilltime}
                            </p>
                        </div>
                        <p className='text-xs lg:text-sm font-semibold'>{trip.route}</p>
                        <button className="w-full mt-2 text-xs px-4 py-2 border border-[#212121] font-semibold rounded-full"> More Details</button>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </>
};
const Tab = ({ title, onClick, active }) => {
    return (
        <button
            onClick={onClick}
            className={`py-2 px-4 font-semibold border-b-2 ${active ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'
                } hover:text-blue-500 focus:outline-none`}
        >
            {title}
        </button>
    );
};
const SelectService = () => {
    return (
        <div className="my-5 py-10 relative">
            {/* <div className="absolute top-10 left-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div> */}
            <div className="">
                <h2 className="text-lg font-semibold mb-2">Select Service</h2>
                <div className="relative inline-block w-full">
                    <select className="block appearance-none w-full bg-white border border-[#FFB703] hover:border-[#212121] px-4 py-2.5 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option>Choose service</option>
                        <option>Service 1</option>
                        <option>Service 2</option>
                        <option>Service 3</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z" /></svg>
                    </div>
                </div>
            </div>
            {/* <div className="absolute bottom-10 right-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div>
            <div className="absolute bottom-10 left-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div>
            <div className="absolute top-10 right-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div> */}
        </div>
    );
};
const ServiceTabs = ({ onTabChange }) => {
    const tabs = [
        { id: 1, label1: 'Daily Rides to the Big ', label2: "Apple: Your Ticket to NYC", image: TAB1 },
        { id: 2, label1: 'AeroRide: Your Quick', label2: "Airport Taxi", image: TAB2 },
        { id: 3, label1: 'DealDazzle', label2: "Discounted Attractions", image: TAB3 },
        { id: 4, label1: 'WanderWise ', label2: "Group Tours & Travel", image: TAB4 },
    ];

    const [activeTab, setActiveTab] = useState(0);
    const [selectedDate, setSelectedDate] = useState('2024-04-13');

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <>
            <div>
                <div className="max-w-7xl mx-auto w-full grid grid-cols-2 lg:grid-cols-4 gap-2 py-4">
                    {tabs.map((tab, index) => (
                        <div
                            key={tab.id}
                            className={`p-4 border border-[#FFB703] rounded-lg ${activeTab === index ? 'tab_bg_color' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            <div className='mb-2'>
                                <img src={tab?.image} alt='img' className='object-cover mx-auto' />
                            </div>
                            <p className='text-center text-[#212121] font-semibold text-base'>
                                {tab.label1}
                            </p>
                            <p className='text-center text-[#212121] font-semibold text-base'>
                                {tab.label2}
                            </p>
                        </div>
                    ))}
                </div>
                <div>
                    {activeTab === 0 &&
                        <div>
                            <div className='max-w-xl mx-auto'>
                                <SelectService />
                            </div>
                            <div className='max-w-7xl mx-auto'>
                                <DatePicker date={selectedDate} onDateChange={handleDateChange} />
                            </div>
                            <div className='my-4'>
                                <TripDetails />
                            </div>
                            <div className='max-w-7xl mx-auto'>
                                <div className='!relative w-full  flex justify-center '>
                                    <img src={Cloudsandcity} alt='Cloudsandcity' className='w-full mx-auto' />
                                    <div className='w-full absolute top-52 z-30'>
                                        <Bookingcomponent trip={false} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {activeTab === 1 &&
                        <div>
                            <section className='max-w-7xl mx-auto my-5 lg:my-10 p-0 lg:px-5'>
                                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                                    <div className='col-span-6 lg:col-span-6'>
                                        <img src={Bookingleftside} alt='About_banner' className='w-full max-w-full mx-auto' />
                                    </div>
                                    <div className='col-span-6 lg:col-span-6'>
                                        <div className='lg:my-6'>
                                            <div className='lg:pr-10 w-full h-full space-y-3 lg:space-y-5'>
                                                <h3 className='font-semibold text-lg'>
                                                    Golden Eagle provides taxis to and from the following major airports, straight to your destination:
                                                </h3>
                                                <ul className='pl-4 lg:pl-8 list-disc text-start'>
                                                    <li>John F. Kennedy International Airport (JFK)</li>
                                                    <li>LaGuardia Airport (LGA)</li>
                                                    <li>Newark Liberty International Airport (EWR)</li>
                                                    <li>Westchester County Airport (HPN)</li>
                                                </ul>
                                                <p className='font-normal'>
                                                    We also offer taxi services to Cruise Line Terminals in New York City and New Jersey, along with other destinations upon request. Rates are determined by travel time, passenger count, and destination. Contact us for taxi inquiries HERE.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div>
                            <section className='max-w-7xl mx-auto my-5 lg:my-10 p-0 lg:px-5'>
                                <div className='text-center relative lg:mb-10'>
                                    <h2 className='lg:w-[60%] mx-auto text-2xl lg:text-[32px] leading-snug text-[#212121] font-semibold'>We partner with some of the best companies so you can experience the best of New Jersey & New York City </h2>
                                </div>
                                <div className='flex justify-center items-center  lg:gap-10'>
                                    <div className=''>
                                        <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto object-contain' />
                                    </div>
                                    <div className=''>
                                        <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto object-contain' />
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {activeTab === 3 && <div>
                        <section className='max-w-7xl mx-auto my-5 lg:my-10 p-0 lg:px-5'>
                            <div className='text-center space-y-4 lg:mb-10 text-[#212121]'>
                                <p className='mx-auto text-base leading-snug font-normal'>Golden Eagle provides Private Tours and Third-Party Tours for hotel guests at discounted prices.<br />
                                    If you’d like more information on a private tour,

                                </p>
                                <p>
                                    PLEASE CONTACT US <span className='underline'>HERE.</span>
                                </p>
                                <p>
                                    We work with the following New York City tour companies for hotel guests interested in tours of the Big Apple:
                                </p>
                                <p className='underline'>
                                    - Big Bus NYC
                                </p>
                            </div>

                        </section>
                    </div>
                    }

                </div>
            </div>
        </>
    );
};
const Booking = () => {

    return (
        <>
            <HeroSection />
            <section className='py-5 lg:py-10'>
                <div className='max-w-7xl mx-auto'>
                    <div className='text-center relative'>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Experience Seamless Travel Service</h2>
                        <p className='lg:w-[42%] mx-auto lg:pb-3'>Discover the Best: Golden Eagle Shuttle Service. 20 years of excellence connecting North Bergen hotels to NYC. Your effortless gateway to the Big Apple awaits!</p>
                    </div>
                </div>
                <div className=''>
                    <ServiceTabs />

                </div>
            </section>

            <section className='max-w-7xl mx-auto my-10'>
                {/* <div className='text-center relative mb-10'>
                    <h2 className='text-[32px] text-[#212121] font-semibold'>Who we are </h2>
                    <p className=' w-[40%] text-base mx-auto'>Golden Eagle runs daily door to door shuttles</p>
                </div> */}
                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                    <div className='col-span-6 lg:col-span-6'>
                        <img src={Bookingleftside} alt='About_banner' className='w-full max-w-full mx-auto' />
                    </div>
                    <div className='col-span-6 lg:col-span-6'>
                        <div className='lg:pr-20 w-full h-full flex flex-col justify-center items-center gap-4'>
                            <h3 className='font-semibold text-lg'>
                                Golden Eagle's NYC Shuttle: Seamless Travel from North Bergen & Jersey City
                            </h3>
                            <p className='font-normal'>
                                Golden Eagle runs daily door to door shuttles to New York City for hotel guests staying in North Bergen, NJ and Jersey City, NJ. The shuttle has scheduled runs and tickets must be purchased at least an hour in advance.                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FaqSection data={bookingData} />

        </>
    )
}

export default Booking