import React from 'react'
import { CiSearch } from 'react-icons/ci'

const Bookingcomponent = ({ trip = true }) => {
  return (
    <div class="w-[85%] mx-auto absolute -bottom-10 right-0 left-0 flex justify-center ">
      <div class="bg-white p-6 rounded-lg shadow-lg ">
        <div className='w-full flex items-center gap-4'>
          {trip && <div class="w-full mb-4">
            <select class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option>Select service</option>
            </select>
          </div>}
          <div class={`${trip ? "w-full" : "w-2/4"} hidden lg:flex mb-4`}>
            <button class="w-1/2 text-center py-2 rounded-tl-lg rounded-bl-lg bg-yellow-400 text-white">One way trip</button>
            <button class="w-1/2 text-center py-2 rounded-tr-lg rounded-br-lg bg-gray-200">Round trip</button>
          </div>
        </div>
        <div class="flex items-center justify-between gap-4 mb-4">
          <div>
            <label>Depart</label>
            <input type="text" placeholder="Depart" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div>
            <label>Return</label>
            <input type="text" placeholder="Return" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          {/* </div> */}
          {/* <div class="grid grid-cols-2 gap-4 mb-4"> */}
          <div>
            <label>From</label>
            <input type="text" placeholder="From" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div>
            <label>To</label>
            <input type="text" placeholder="To" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div class="text-center mt-4">
            <button class="bg-[#FFB703] shadow-md shadow-[#FFB703] text-black text-base p-2 rounded-full">
              <CiSearch />
            </button>
          </div>
        </div>
      </div>
    </div >)
}

export default Bookingcomponent