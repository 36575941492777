import React, { useEffect } from 'react'
import { Book_banner } from '../../helper/imageConstant'
import HeroSection from './HeroSection';
import HotelsSection from './HotelsSection';
import ShuttleOffersSection from './ShuttleOffersSection';
import ServicesSection from './ServicesSection';
import ClientSection from './ClientSection';
import OurpartnerSection from './OurpartnerSection';
import CtaSection from './CtaSection';
import FaqSection from './FaqSection';
import ContactSection from './ContactSection';
import PackageSection from './PackageSection';
import { faqData } from '../../helper/constant';
import { fetchProducts } from '../../statemanagement/product/productaction';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
  let dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchProducts())
  },[])

  const {loading, products, error} = useSelector(state => state.product)
  console.log('loading', loading, products, error)


  return (
    <>
      <HeroSection booking />
      <HotelsSection />
      <ShuttleOffersSection />
      <ServicesSection />
      <ClientSection />
      <PackageSection />
      <section className='p-0'>
        <div className='relative w-full h-full'>
          <img src={Book_banner} alt='' className='w-full h-[400px] lg:h-full mx-auto object-cover' />
          <div className='absolute bg-[#000000] bg-cover bg-center bg-opacity-60 top-0 left-0 right-0 bottom-0 flex justify-center items-center h-full'>
            <div className='text-center'>
              <h2 className='w-2/3 mx-auto lg:w-full text-xl lg:text-[32px] text-[#fff] font-semibold mb-2'>Ready to take on NYC's adventures?</h2>
              <p className='lg:w-[55%] mb-2 lg:mb-0 px-4 mx-auto text-base lg:text-xl leading-normal text-[#fff] pb-3'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
              <button className="text-[#212121] capitalize font-semibold rounded-full text-base bg-gradient-to-r from-[#FFB703] to-[#FD9D01] px-4 py-2">book now</button>
            </div>
          </div>
        </div>
      </section>
      <OurpartnerSection />
      <CtaSection />
      <FaqSection data={faqData} />
      <ContactSection />
    </>
  )
}

export default Home