import React from 'react'
import { Menu_icon, Nav_logo } from '../helper/imageConstant'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <header className='!sticky top-0 z-50'>
        <div className='bg-gradiant-yellow p-3 lg:p-2'></div>
        <nav className="bg-white">
          <div className="mx-auto max-w-7xl">
            <div className="px-5 py-2.5 lg:p-2.5">
              <div className="flex items-center justify-between ">
                <div className="flex flex-shrink-0 items-center">
                  <img className='w-8 lg:hidden' src={Menu_icon} alt='Menu_icon' />
                  <img className="w-16" src={Nav_logo} alt="logo" />
                </div>
                <div className="flex items-center gap-4">
                  <div className='hidden lg:block space-x-4'>
                    <Link to="/" className="text-[#080909] hover:text-[#FFB703] text-sm font-medium" aria-current="page">Home</Link>
                    <Link to="/timetable-booking" className="text-[#080909] hover:text-[#FFB703] text-sm font-medium">Timetable Harmony & Booking Blend</Link>
                    <Link to="/about-us" className="text-[#080909] hover:text-[#FFB703] text-sm font-medium">About Us</Link>
                    <Link to="/contact-us" className="text-[#080909] hover:text-[#FFB703] text-sm font-medium">Contact Us</Link>
                  </div>
                  <div>
                    <button className='header_btn'>Book now</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </nav >
      </header>
    </>

  )
}

export default Header