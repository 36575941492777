import React from 'react'
import HeroSection from '../home/HeroSection'
import { About_banner, Aboutleftside } from '../../helper/imageConstant'

const AboutUs = () => {
    return (
        <>
            <HeroSection />
            <section className='max-w-7xl mx-auto my-5 lg:my-20 mb-0 pb-0 lg:py-10'>
                <div className='text-center relative mb-8 lg:mb-16'>
                    <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>About Us</h2>
                    <p className='lg:w-[40%] text-base mx-auto'>Discover the Best: Golden Eagle Shuttle Service. 20 years of excellence connecting North Bergen hotels to NYC. Your effortless gateway to the Big Apple awaits!</p>
                </div>
                <div className='space-y-4 lg:space-y-8 mb-10 lg:mb-16'>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Consectetur purus aliquam amet libero. Aliquet etiam tortor donec tempus. Felis parturient eget arcu egestas. Viverra senectus malesuada sed quis. Arcu sem risus fringilla at. Porta consectetur cursus pulvinar consectetur integer convallis nisi. Luctus nulla orci malesuada pharetra. Libero nunc auctor erat enim maecenas praesent non amet. Mattis arcu a eu dolor sit. Nec egestas a elementum nunc eu a feugiat tristique. Morbi turpis metus eget dignissim gravida a.
                    </p>
                    <p>
                        Mauris gravida pellentesque proin eget sit. Accumsan pulvinar ipsum dui feugiat ut vitae tristique. Cras elit amet placerat cursus blandit varius. Habitasse velit imperdiet ullamcorper arcu vulputate quis. Ac nec ac eu sit at. Dictum sed facilisi consectetur id vehicula et. Eu diam velit non nulla turpis. Et odio dignissim pretium luctus morbi vitae odio elementum .
                    </p>
                </div>
                <div className=''>
                    <img src={About_banner} alt='About_banner' className='w-full max-w-full mx-auto' />
                </div>
            </section>
            <section className='max-w-7xl mx-auto my-10'>
                <div className='text-center relative mb-10'>
                    <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Who we are </h2>
                    <p className='lg:w-[40%] text-base mx-auto'>Golden Eagle runs daily door to door shuttles</p>
                </div>
                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                    <div className='col-span-6 lg:col-span-6'>
                        <img src={Aboutleftside} alt='About_banner' className='w-full max-w-full mx-auto' />
                    </div>
                    <div className='col-span-6 lg:col-span-6'>
                        <div className='lg:pr-16 w-full h-full flex flex-col justify-center items-center gap-5 lg:gap-10'>
                            <p>
                                For the last 20 years, Golden Eagle Shuttle Service has been serving hotels in the North Bergen, New Jersey area - helping guests travel to New York City and experience everything the Big Apple has to offer. We want to make it easy for you to get to the City.
                            </p>
                            <p className='text-[#FB8500]'>
                                We specialize in daily shuttles to New York City, airport taxis, private tours, and discounted group lavel
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUs