import React from 'react'
import Bookingcomponent from '../../component/Bookingcomponent'

const HeroSection = ({ booking }) => {
  return (
    <section className='p-0 hero_section relative lg:max-w-7xl mx-auto'>
      <div className='hero_bg'>
        <div className='hero_text text-center'>
          <h2 className=''>
            Explore the whole world
            <br />
            and enjoy its beauty
          </h2>
        </div>
      </div>
      {booking && <Bookingcomponent />}
      {/* <div className="mx-auto search-container d-flex justify-content-center">
          <form>
            <div className="row mb-3">
              <div className="col">
                <select className="form-select">
                  <option>Select service</option>
                </select>
              </div>
              <div className="col">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="tripType" id="oneWay" defaultChecked />
                  <label className="form-check-label" htmlFor="oneWay">One way trip</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="tripType" id="roundTrip" />
                  <label className="form-check-label" htmlFor="roundTrip">Round trip</label>
                </div>
              </div>
            </div>
            <div className="row w-100 d-flex align-items-center justify-content-between">
              <div className="col">
                <label>Depart</label>
                <input type="time" className="form-control" placeholder="Depart" />
              </div>
              <div className="col">
                <label>Return</label>

                <input type="time" className="form-control" placeholder="Return" />
              </div>
              <div className="col">
                <label>From</label>
                <input type="text" className="form-control" placeholder="From" />
              </div>
              <div className="col">
                <label>To</label>
                <input type="text" className="form-control" placeholder="To" />
              </div>
              <div className='col search_btn pt-3'>
                <button type="submit" className="btn btn-warning rounded-circle">
                  <CiSearch />
                </button>
              </div>
            </div>
          </form>
        </div> */}
    </section>)
}

export default HeroSection