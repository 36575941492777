import React from 'react'
import { Book_img1 } from '../../helper/imageConstant'
import { Swiper, SwiperSlide } from 'swiper/react';

const ShuttleOffersSection = () => {
    const SoffersImg = [
        {
            src: Book_img1
        },
        {
            src: Book_img1
        },
        {
            src: Book_img1
        },
        {
            src: Book_img1
        },
        {
            src: Book_img1
        },

    ]
    return (
        <section className='max-w-7xl mx-auto my-10'>
            <div className='text-center relative'>
                <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold lg:title-line pb-2'>Shuttle Offers</h2>
            </div>
            <div className='max-w-7xl mx-auto py-3 lg:py-5'>
                <div className=''>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true
                            },
                            // 768: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 40,
                            // },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 50,
                            // },
                        }}
                        className="mySwiper"

                    >
                        {SoffersImg?.map((i, j) =>
                            <SwiperSlide key={j} className=''>
                                <div className='w-full relative'>
                                    <img src={i?.src} alt={i?.src} className='w-full mx-auto' />
                                    <div className='w-2/3 lg:w-3/5 absolute bottom-0 left-0 lg:left-5 p-6 lg:p-8 bg-[#000000] bg-opacity-50 rounded-bl-3xl lg:rounded-t-3xl font-Tsukimi'>
                                        <p className='text-white text-sm lg:text-3xl leading-normal text-center'>Book for 3 ride
                                            & get 4 ride
                                            free free free </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default ShuttleOffersSection