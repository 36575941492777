import React, { useState } from 'react'
import { Services1, Services2, Services3, Services4 } from '../../helper/imageConstant'
import UserDetailsPopup from '../../component/UserDetailsPopup';
import { Swiper, SwiperSlide } from 'swiper/react';

const ServicesSection = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };
    const serviceObj = [
        {
            src: Services1,
            description: "Door-to-door shuttles straight from your hotel to New York City in 45 minutes."
        },
        {
            src: Services2,
            description: "Provide taxi service from JFK, LGA, and EWR Airports, as well as Cruise Terminals  "

        },
        {
            src: Services3,
            description: "Large groups can receive discounts on shuttles and travel if booked in advance."

        },
        {
            src: Services4,
            description: "Provide discounts on third-party tours and offer private tour options."

        },
        {
            src: Services1,
            description: "Door-to-door shuttles straight from your hotel to New York City in 45 minutes."
        },
        {
            src: Services2,
            description: "Provide taxi service from JFK, LGA, and EWR Airports, as well as Cruise Terminals  "

        },
        {
            src: Services3,
            description: "Large groups can receive discounts on shuttles and travel if booked in advance."

        },
        {
            src: Services4,
            description: "Provide discounts on third-party tours and offer private tour options."

        },

    ]
    return (
        <section className='max-w-7xl mx-auto my-10'>
            <div className='text-center relative'>
                <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold'>Services</h2>
                <p className='lg:w-[45%] mx-auto lg:title-line lg:pb-3'>Lorem ipsum dolor sit amet consectetur. Pellentesque in fringilla eleifend potenti eu diam. Odio et tristique elemen .</p>
            </div>

            <div className='max-w-7xl mx-auto py-2 lg:py-5'>
                <div class="lg:py-8">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                loop: true
                            },
                            // 768: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 40,
                            // },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 50,
                            // },
                        }}
                        className="mySwiper"

                    >
                        {serviceObj?.map((i, j) =>
                            <SwiperSlide key={j} className=''>
                                <div class="border border-[#FFB703] rounded-[50px] p-4 w-full text-center">
                                    <img src={i?.src} alt="Daily Shuttles to New York City" class="mx-auto mb-4" />
                                    <div className='flex flex-col justify-between'>
                                        <div className=''>
                                            <h2 class="w-[60%] lg:w-full mx-auto text-lg font-semibold mb-2">Daily Shuttles to New York City</h2>
                                            <p class="w-[70%] lg:w-full mx-auto text-[#212121] text-[15px] mb-4">{i?.description}</p>
                                        </div>
                                        <div>
                                            <button onClick={togglePopup} class="bg-gradiant-yellow-secondary text-[#000000] font-light rounded-full p-4 group/item ">
                                                <svg className='group-hover/item:scale-125' width="18" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.36442 1.18568C7.87986 1.71943 9.17682 2.00728 10.3566 2.16083C11.8764 2.36133 13.4238 2.32257 14.919 1.9894C16.0401 1.73952 17.2941 1.32789 17.9817 0.663796M17.9817 0.663796C17.2941 1.32789 16.8382 2.56746 16.5501 3.67841C16.1656 5.1618 16.0729 6.70686 16.2188 8.23134C16.3318 9.41648 16.5756 10.7241 17.0896 11.2564M17.9817 0.663796L0.717595 17.3356" stroke="black" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
            <UserDetailsPopup isOpen={isPopupOpen} onClose={togglePopup} />

        </section>
    )
}

export default ServicesSection