import React from 'react'
import { FaFacebook, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { Nav_logo } from '../../helper/imageConstant';

const CtaSection = ({ isText }) => {
    return (
        <section class="max-w-8xl mx-auto p-0 lg:px-5 lg:py-5">
            <div className='text-center relative'>
                {isText ?
                    <>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Contact Us</h2>
                        <p className='lg:w-[50%] mx-auto lg:pb-3'>WE’D LOVE TO HEAR FROM YOU!</p>
                    </>
                    :
                    <>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Location, Service, Routes: Ready for You !</h2>
                        <p className=' lg:w-[50%] mx-auto lg:title-line lg:pb-3'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
                    </>}
            </div>
            <div className='max-w-7xl mx-auto py-5'>
                <div class="bg-gray-900 text-white p-4 lg:rounded-3xl grid grid-cols-6 lg:grid-cols-12 gap-4">
                    <div className='col-span-6 lg:col-span-3 w-full lg:p-4'>
                        <div className='flex lg:hidden items-center justify-end gap-2'>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaFacebook />
                            </span>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaRegEnvelope /></span>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaInstagram /></span>
                        </div>
                        <div className='w-full mb-10'>
                            <img src={Nav_logo} alt='' className='w-60 mx-auto' />
                        </div>
                        <div class="mb-4 border-b border-dashed pb-2 border-[#FFFFFF]">
                            <h3 class="text-lg font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] inline-block">Location</h3>
                            <p className='text-sm'>New York, USA</p>
                        </div>
                        <div class="">
                            <h3 class="text-lg font-bold mb-2  text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] inline-block">Available time</h3>
                            <div class="w-full text-sm grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-1">
                                <div className='flex justify-between'>SUN <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>MON <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>TUE <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>WED <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>THU <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>FRI <span> 8:00 AM - 10:00PM UST </span></div>
                                <div className='flex justify-between'>SAT <span> 8:00 AM - 10:00PM UST </span></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6 lg:col-span-9 w-full space-y-4'>
                        <div className='hidden lg:flex items-center justify-end gap-2'>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaFacebook />
                            </span>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaRegEnvelope /></span>
                            <span className='bg-[#FFFFFF] text-[#FB8500] text-xl p-1.5 rounded-full'>
                                <FaInstagram /></span>
                        </div>
                        <div class="flex flex-col lg:flex-row justify-between gap-5 lg:gap-0 w-full">
                            <div class="flex items-center space-x-2 order-2 lg:order-1">
                                <span class="bg-[#00EA5D] h-3 w-3 rounded-full"></span>
                                <span className='text-[#00EA5D] text-lg font-semibold uppercase'>Service Online</span>
                            </div>
                            <div class="text-lg font-semibold uppercase text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] inline-block order-1 lg:order-2">Current Time: 10:15AM</div>
                        </div>
                        <div class=" w-full mb-4">
                            <img src="https://via.placeholder.com/800x400" alt="Map" class="w-full rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default CtaSection