// src/reducers/index.js
import { combineReducers } from 'redux';
import productReducer from './product/productreducers';

const rootReducer = combineReducers({
  product: productReducer,
  // Add other reducers here as your application grows
});

export default rootReducer;
