import React from 'react'
import { Catchy_line, Contact_img } from '../../helper/imageConstant'

const ContactSection = () => {

    return (
        <>
            <section className='max-w-7xl mx-auto p-0 lg:p-5 my-10'>
                <div className="w-full h-full">
                    <div className="flex bg-black text-white lg:rounded-lg shadow-lg overflow-hidden">
                        <div className="hidden md:block w-[40%]">
                            <img src={Contact_img} alt="Contact" className="h-full object-cover" />
                        </div>
                        <div className="p-5 lg:p-10 lg:w-[60%]">
                            <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
                            <p className="mb-6">
                                Lorem ipsum dolor sit amet consectetur. Natoque est pellentesque scelerisque ornare justo suspendisse.
                            </p>
                            <form className="space-y-8">
                                <div className='space-y-4'>
                                    <div>
                                        <label className="block text-sm mb-1" htmlFor="name">Name</label>
                                        <input type="text" id="name" className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md" />
                                    </div>
                                    <div>
                                        <label className="block text-sm mb-1" htmlFor="email">Email</label>
                                        <input type="email" id="email" className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md" />
                                    </div>
                                    <div>
                                        <label className="block text-sm mb-1" htmlFor="message">Message</label>
                                        <textarea id="message" className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md" rows="4"></textarea>
                                    </div>
                                </div>
                                <button type="submit" className="w-full mt-2 lg:mt-0 py-2 border-gradient border-gradient-yellow rounded-md text-white font-bold">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <div className='mt-10'>
                <div className='max-w-7xl mx-auto mb-10'>
                    <div className='text-center relative'>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold lg:title-line lg:pb-2'>Add catchy line</h2>
                    </div>
                </div>
                <div className='w-full h-full'>
                    <img src={Catchy_line} alt='Catchy_line' className='object-cover mx-auto w-full' />
                </div>
            </div>
        </>
    )
}

export default ContactSection