import React from 'react'
import HeroSection from '../home/HeroSection'
import CtaSection from '../home/CtaSection'
import ContactSection from '../home/ContactSection'

const ContactUs = () => {
    return (
        <>
            <HeroSection />
            <div className='my-10'>
                <CtaSection isText />
            </div>
            <ContactSection />

        </>
    )
}

export default ContactUs