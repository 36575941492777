import React from 'react'
import { Hotel_img1, Hotel_img2, Hotel_img3, Hotel_img4 } from '../../helper/imageConstant'
import { SlLocationPin } from 'react-icons/sl'
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules'

const hotelImg = [
    {
        src: Hotel_img1
    },
    {
        src: Hotel_img2
    },
    {
        src: Hotel_img3
    },
    {
        src: Hotel_img4
    },
    {
        src: Hotel_img1
    },
    {
        src: Hotel_img2
    },
    {
        src: Hotel_img3
    },
    {
        src: Hotel_img4
    },

]
const HotelsSection = () => {
    return (
        <section className='max-w-7xl mx-auto my-20'>
            <div className='flex flex-col lg:flex-row justify-between gap-3 items-center '>
                <div className='text-center lg:text-start'>
                    <h2 className='text-[32px] text-[#212121] font-semibold'>Hotels we serve</h2>
                    <p className='text-base text-[#212121] font-normal'>Lorem ipsum dolor sit amet consectetur. </p>
                </div>
                <div className='text-center lg:text-start'>
                    <p className='text-base text-[#212121] font-normal'>Lorem ipsum dolor sit amet consectetur. Pellentesque in fringilla
                        <br className='hidden lg:block' />eleifend potenti eu diam. Odio et tristique elemen . </p>
                </div>
            </div>
            <div className='max-w-7xl mx-auto py-5'>
                <div className='w-full'>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={2}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                loop: true
                            },
                            // 768: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 40,
                            // },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 50,
                            // },
                        }}
                        className="mySwiper"

                    >
                        {hotelImg?.map((i, j) => (
                            <SwiperSlide key={j} className=''>
                                <div className='w-full space-y-2'>
                                    <img src={i?.src} alt='hotel' className='w-full mx-auto' />
                                    <div className='mt-2'>
                                        <h2>Hotels Name</h2>
                                        <div className='flex items-center gap-2 '>
                                            <SlLocationPin className='text-[#FFB703]' />
                                            <p className=''>2972 Westheimer Rd. Santa ...</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                        )}
                    </Swiper>

                </div>
            </div>
        </section>
    )
}

export default HotelsSection